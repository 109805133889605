import axios, { AxiosInstance } from 'axios';
import { BASE_HEADERS, BASE_URL } from '../globals';

let AUTH_URL: string = `${BASE_URL}/user/`;

const newAxios: AxiosInstance = axios.create({
    baseURL: AUTH_URL,
    headers: {
        ...BASE_HEADERS
    }
});

newAxios.interceptors.request = axios.interceptors.request;
newAxios.interceptors.response = axios.interceptors.response;

export default newAxios;