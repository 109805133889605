import { DeviceModel } from 'src/models/device.model';
import { NotificationModel, NOTIFICATIONS, SEVERITIES, TYPES } from "../models/notification.model";
import notificationsApi from '../apis/notifications';
import { getUser } from 'src/actions';

class NotificationService {
    
    private static _instance: NotificationService;
    
    constructor() {}

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    addNotificationToDevice = async (updatedDevice: DeviceModel, patientId: string | undefined) => {
        const response = await notificationsApi.get(`${patientId}`);
        const tenantId = JSON.parse(getUser()).tenantId;
        
        if (response.status === 200) {
            updatedDevice.notifications = this.getNotifications(response.data);
            if (updatedDevice.shadow.state.is_healthy === false) {
                updatedDevice.notifications.unshift({
                    patient_id: updatedDevice.patient?._id,
                    tenant_id: tenantId,
                    timestamp: updatedDevice.shadow.state.is_healthy_timestamp as number,
                    configurations: {
                        notification: NOTIFICATIONS.TECHNICAL_INDICATOR_ON,
                        severity: SEVERITIES.ACTION_REQUIRED,
                        type: TYPES.STATE,
                        text: "The device is unavailable due to a technical reason. Contact support."
                    },
                })
            }
        }
        
        return updatedDevice;
    }

    public getNotifications(data: any) {
        const notifications = data.filter((notificationObj: NotificationModel) => {
            let notificationsNamesToRemove: any[] = [];
            data.map((notification: NotificationModel) => {
                if (notification.configurations.remove) {
                    notificationsNamesToRemove = [
                        ...notificationsNamesToRemove,
                        {
                            timestamp: notification.timestamp,
                            remove: notification.configurations.remove
                        }
                    ];
                }
            });

            for (let key in notificationsNamesToRemove) {
                if (notificationsNamesToRemove[key].remove.includes(notificationObj.configurations.notification) &&
                    notificationsNamesToRemove[key].timestamp > notificationObj.timestamp) {
                    return false;
                }

            }
            return true;
        });

        return notifications.filter((notification: NotificationModel) => notification.configurations.is_hidden !== true);
    }

    public async updateNotificationStatus(patientId: string, notifications: NotificationModel[] | undefined) {
        return await notificationsApi.patch(`${patientId}`, notifications);
    }
}

export default NotificationService.Instance;