import { Action, Dispatch } from "redux";
import recordingApi from "../apis/recording";
import slotApi from "../apis/slot";
import preCommunicationSlotApi from "../apis/preCommunicationSlot";
import { RecordingEventActionTypes } from "./types";
import {
    FamilyRecordingAttribute,
    FamilyRecordingModel,
    GeneralRecordingModel,
    PreCommunicationSlotModel,
    RecordingSlotsModel
} from "../models/recording.model";
import { httpStatus } from "../constans";
import { sleep, sortByName } from "../utils/generalMethods";
import amplifyService from "../services/amplifyService";
import { StoreState } from "../reducers";
import { TOAST_SEVERITY } from "../models/toast.model";
import { ThunkDispatch } from "redux-thunk";
import { getUser } from "./authentication.action";

export interface FetchFamilyRecordingsAction {
    type: RecordingEventActionTypes.FETCH_FAMILY_RECORDINGS;
    payload: {
        patientId: string;
        familyRecordings: FamilyRecordingModel[];
    };
}

export interface FetchGeneralRecordingsAction {
    type: RecordingEventActionTypes.FETCH_GENERAL_RECORDINGS;
    payload: {
        patientId: string;
        generalRecordings: GeneralRecordingModel[];
    };
}

export interface FetchRecordingSlotAction {
    type: RecordingEventActionTypes.FETCH_RECORDING_SLOTS;
    payload: {
        patientId: string;
        slots: RecordingSlotsModel[];
    };
}

export interface FetchPreCommunicationSlotAction {
    type: RecordingEventActionTypes.FETCH_PRE_COMMUNICATION_SLOT;
    payload: {
        patientId: string;
        preCommunicationSlot: PreCommunicationSlotModel;
    };
}

export interface UpdateFamilyRecordingAction {
    type: RecordingEventActionTypes.UPDATE_FAMILY_RECORDING;
    payload: {
        patientId: string;
        updatedRecording: FamilyRecordingModel;
    };
}

export interface UpdateRecordingSlot {
    type: RecordingEventActionTypes.UPDATE_RECORDING_SLOT;
    payload: {
        patientId: string;
        slotNumber: number;
        recordingId: string;
    };
}

export interface UpdatePreCommunicationSlot {
    type: RecordingEventActionTypes.UPDATE_PRE_COMMUNICATION_SLOT;
    payload: {
        patientId: string;
        slotName: string;
        recordingId: string;
    };
}

export interface DeleteRecordingSlot {
    type: RecordingEventActionTypes.DELETE_RECORDING_SLOT;
    payload: {
        patientId: string;
        slotNumber: number;
    };
}

export interface DeletePreCommunicationgSlot {
    type: RecordingEventActionTypes.DELETE_PRE_COMMUNICATION_SLOT;
    payload: {
        patientId: string;
    };
}

export interface SortFamilyRecordingAction {
    type: RecordingEventActionTypes.SORT_FAMILY_RECORDINGS;
    payload: {
        patientId: string;
    };
}

export interface FamilyRecordingsPendingAction {
    type: RecordingEventActionTypes.FAMILY_RECORDINGS_PENDING;
    payload: {
        patientId: string;
    };
  }
  
  export interface SlotsPendingAction {
    type: RecordingEventActionTypes.SLOTS_PENDING;
    payload: {
        patientId: string;
    };
  }
  
  export interface GeneralRecordingsPendingAction {
    type: RecordingEventActionTypes.GENERAL_RECORDINGS_PENDING;
    payload: {
        patientId: string;
    };
  }

  export interface FamilyRecordingsErrorAction {
    type: RecordingEventActionTypes.FAMILY_RECORDINGS_ERROR;
    payload: {
      patientId: string;
      error: string;
    };
  }
  
  export interface SlotsErrorAction {
    type: RecordingEventActionTypes.SLOTS_ERROR;
    payload: {
      patientId: string;
      error: string;
    };
  }
  
  export interface GeneralRecordingsErrorAction {
    type: RecordingEventActionTypes.GENERAL_RECORDINGS_ERROR;
    payload: {
      patientId: string;
      error: string;
    };
  }

export type RecordingAction =
    | FetchFamilyRecordingsAction
    | UpdateFamilyRecordingAction
    | SortFamilyRecordingAction
    | FetchGeneralRecordingsAction
    | FetchRecordingSlotAction
    | UpdateRecordingSlot
    | DeleteRecordingSlot
    | FetchPreCommunicationSlotAction
    | UpdatePreCommunicationSlot
    | DeletePreCommunicationgSlot
    | FamilyRecordingsPendingAction
    | SlotsPendingAction
    | GeneralRecordingsPendingAction
    | FamilyRecordingsErrorAction
    | SlotsErrorAction
    | GeneralRecordingsErrorAction;

export const fetchFamilyRecordings = (patientId: string) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch<FamilyRecordingsPendingAction>({
                type: RecordingEventActionTypes.FAMILY_RECORDINGS_PENDING,
                payload: {
                    patientId: patientId,
                },
            });

            const response = await recordingApi.get(`family/${patientId}`);

            if (response.status === 200) {
                dispatch<FetchFamilyRecordingsAction>({
                    type: RecordingEventActionTypes.FETCH_FAMILY_RECORDINGS,
                    payload: {
                        patientId: patientId,
                        familyRecordings: response.data,
                    },
                });
            }
        } catch (error) {
            console.log(error);
            dispatch<FamilyRecordingsErrorAction>({
                type: RecordingEventActionTypes.FAMILY_RECORDINGS_ERROR,
                payload: {
                    patientId: patientId,
                    error: JSON.stringify(error)
                },
            });
        }
    };
};

export const fetchRecordingSlots = (patientId: string) => {
    return async (dispatch: Dispatch) => {

        try {
            dispatch<SlotsPendingAction>({
                type: RecordingEventActionTypes.SLOTS_PENDING,
                payload: {
                    patientId: patientId,
                },
            });

            const response = await slotApi.get(patientId);

            dispatch<FetchRecordingSlotAction>({
                type: RecordingEventActionTypes.FETCH_RECORDING_SLOTS,
                payload: {
                    patientId: patientId,
                    slots: response?.data?.Items as RecordingSlotsModel[]
                },
            });
        }
        catch(error) {
            dispatch<FamilyRecordingsErrorAction>({
                type: RecordingEventActionTypes.FAMILY_RECORDINGS_ERROR,
                payload: {
                    patientId: patientId,
                    error: JSON.stringify(error)
                },
            });
            console.log("");
        }
    };
};

export const updateRecordingSlot = (
    patientId: string,
    slotNumber: number,
    recordingId: string,
    prevId: string,
    callback: Function
) => {
    return async (dispatch: Dispatch) => {
        dispatch<UpdateRecordingSlot>({
            type: RecordingEventActionTypes.UPDATE_RECORDING_SLOT,
            payload: {
                patientId,
                slotNumber,
                recordingId,
            },
        });

        try {
            const response = await slotApi.post(patientId, {
                number: slotNumber,
                recording_id: recordingId,
            });
            if (response?.data?.$metadata?.httpStatusCode === 200) {
                callback(true);
            } else {
                throw new Error(`Server error`);
            }
        } catch (error) {
            dispatch<UpdateRecordingSlot>({
                type: RecordingEventActionTypes.UPDATE_RECORDING_SLOT,
                payload: {
                    patientId,
                    slotNumber,
                    recordingId: prevId,
                },
            });
            callback(false);
        }

    };
};

export const deleteRecordingSlot = (
    patientId: string,
    slotNumber: number,
    prevId: string,
    callback: Function
) => {
    return async (dispatch: Dispatch) => {
        dispatch<DeleteRecordingSlot>({
            type: RecordingEventActionTypes.DELETE_RECORDING_SLOT,
            payload: {
                patientId,
                slotNumber
            },
        });

        try {
            const response = await slotApi.delete(`${patientId}/${slotNumber}`);
            if (response?.data?.$metadata?.httpStatusCode === 200) {
                callback(true);
            } else {
                throw new Error(`Server error`);
            }
        } catch (error) {
            dispatch<UpdateRecordingSlot>({
                type: RecordingEventActionTypes.UPDATE_RECORDING_SLOT,
                payload: {
                    patientId,
                    slotNumber,
                    recordingId: prevId,
                },
            });
            callback(false);
        }
    };
};

export const fetchPreCommunicationSlots = (patientId: string, slotName: string) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch<SlotsPendingAction>({
                type: RecordingEventActionTypes.SLOTS_PENDING,
                payload: {
                    patientId: patientId,
                },
            });

            const response = await preCommunicationSlotApi.get(`${patientId}/slot/${slotName}`);

            dispatch<FetchPreCommunicationSlotAction>({
                type: RecordingEventActionTypes.FETCH_PRE_COMMUNICATION_SLOT,
                payload: {
                    patientId: patientId,
                    preCommunicationSlot: response?.data?.Item as PreCommunicationSlotModel
                },
            });
        }
        catch(error) {
            dispatch<SlotsErrorAction>({
                type: RecordingEventActionTypes.SLOTS_ERROR,
                payload: {
                    patientId: patientId,
                    error: JSON.stringify(error)
                },
            });
            console.log("");
        }
    };
};

export const updatePreCommunicationSlot = (
    patientId: string,
    slotName: string,
    recordingId: string,
    prevId: string,
    callback: Function
) => {
    return async (dispatch: Dispatch) => {
        dispatch<UpdatePreCommunicationSlot>({
            type: RecordingEventActionTypes.UPDATE_PRE_COMMUNICATION_SLOT,
            payload: {
                patientId,
                slotName,
                recordingId,
            },
        });

        try {
            const response = await preCommunicationSlotApi.post(patientId, {
                name: slotName,
                recording_id: recordingId,
            });
            if (response?.data?.$metadata?.httpStatusCode === 200) {
                callback(true);
            } else {
                throw new Error(`Server error`);
            }
        } catch (error) {
            dispatch<UpdatePreCommunicationSlot>({
                type: RecordingEventActionTypes.UPDATE_PRE_COMMUNICATION_SLOT,
                payload: {
                    patientId,
                    slotName,
                    recordingId: prevId,
                },
            });
            callback(false);
        }

    };
};

export const deletePreCommunicationSlot = (
    patientId: string,
    slotName: string,
    prevId: string,
    callback: Function
) => {
    return async (dispatch: Dispatch) => {
        dispatch<DeletePreCommunicationgSlot>({
            type: RecordingEventActionTypes.DELETE_PRE_COMMUNICATION_SLOT,
            payload: {
                patientId
            },
        });

        try {
            const response = await preCommunicationSlotApi.delete(`${patientId}/slot/${slotName}`);
            if (response?.data?.$metadata?.httpStatusCode === 200) {
                callback(true);
            } else {
                throw new Error(`Server error`);
            }
        } catch (error) {
            dispatch<UpdatePreCommunicationSlot>({
                type: RecordingEventActionTypes.UPDATE_PRE_COMMUNICATION_SLOT,
                payload: {
                    patientId,
                    slotName,
                    recordingId: prevId,
                },
            });
            callback(false);
        }
    };
};

export const fetchGeneralRecordings = (patientId: string) => {
    return async (dispatch: Dispatch, getState: () => StoreState) => {
        try {

            dispatch<GeneralRecordingsPendingAction>({
                type: RecordingEventActionTypes.GENERAL_RECORDINGS_PENDING,
                payload: {
                    patientId: patientId,
                },
            });
            const response = await recordingApi.get(`general/${patientId}`);
            let recordings = response?.data;
            recordings = sortByName(recordings);            

            if (response.status === 200) {
                dispatch<FetchGeneralRecordingsAction>({
                    type: RecordingEventActionTypes.FETCH_GENERAL_RECORDINGS,
                    payload: {
                        patientId: patientId,
                        generalRecordings: recordings as GeneralRecordingModel[],
                    },
                });
            }

            const currentStore = getState();

            for (let recording of recordings as any[]) {
                recording.links = await Promise.all(
                    recording.path.map(async (fileName: any) => {
                        const tenantId = JSON.parse(getUser()).tenantId;
                        const link = await amplifyService.getStorage.get(
                            `${tenantId}/${patientId}/general-recordings/${fileName}`,
                            { level: "public" }
                        );
                        return link;
                    })
                );
            }

            if (response.status === 200) {
                dispatch<FetchGeneralRecordingsAction>({
                    type: RecordingEventActionTypes.FETCH_GENERAL_RECORDINGS,
                    payload: {
                        patientId: patientId,
                        generalRecordings: recordings as GeneralRecordingModel[],
                    },
                });
            }
        } catch (error) {
            dispatch<GeneralRecordingsErrorAction>({
                type: RecordingEventActionTypes.GENERAL_RECORDINGS_ERROR,
                payload: {
                    patientId: patientId,
                    error: JSON.stringify(error)
                },
            });
            console.log(error);
        }
    };
};

export const uploadGeneralRecordings = (
    patientId: string,
    files: File[],
    callback: Function
) => {
    return async (dispatch:  ThunkDispatch<StoreState, void, Action>) => {
        try {
            const formData = new FormData();
            files.forEach((file, index) => {
                formData.append(`files[]`, file);
            });
            const response = await recordingApi.post(
                `general/${patientId}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.data === true) {
                callback(TOAST_SEVERITY.SUCCESS);
                dispatch(fetchGeneralRecordings(patientId));
            }
            else {
                callback(TOAST_SEVERITY.ERROR, response.data.message);
            }
        } catch (err) {
            callback(TOAST_SEVERITY.ERROR);
        }
    };
};

export const sortFamilyRecordings = (
    patientId: string,
) : SortFamilyRecordingAction => {
    return {
        type: RecordingEventActionTypes.SORT_FAMILY_RECORDINGS,
        payload: {
            patientId: patientId
        }
    };
};

const _updateFamilyRecordings = (
    patientId: string,
    familyRecording: FamilyRecordingModel,
    dispatch: Dispatch
) => {
    dispatch<UpdateFamilyRecordingAction>({
        type: RecordingEventActionTypes.UPDATE_FAMILY_RECORDING,
        payload: {
            patientId: patientId,
            updatedRecording: familyRecording,
        },
    });
};

export const updateFamilyRecording = (
    patientId: string,
    familyRecording: FamilyRecordingModel,
    prevFamilyRecording: FamilyRecordingModel,
    attribute: FamilyRecordingAttribute,
    errorCallback: Function
) => {
    return async (dispatch: Dispatch) => {
        try {
            _updateFamilyRecordings(patientId, familyRecording, dispatch);

            const response = await recordingApi.patch(`family/${patientId}`, {
                id: familyRecording.id,
                attribute_name_to_update: attribute,
                attribute_value: familyRecording[attribute],
            });

            if (response?.data?.$metadata?.httpStatusCode !== httpStatus.OK) {
                _updateFamilyRecordings(
                    patientId,
                    prevFamilyRecording,
                    dispatch
                );
                errorCallback("updateFamilyRecording: server error");
            } else {
                await sleep(200);
                dispatch<SortFamilyRecordingAction>({
                    type: RecordingEventActionTypes.SORT_FAMILY_RECORDINGS,
                    payload: {
                        patientId: patientId,
                    },
                });
            }
        } catch (e) {
            _updateFamilyRecordings(patientId, prevFamilyRecording, dispatch);
            errorCallback("updateFamilyRecording: server error");
            console.log(e);
        }
    };
};
