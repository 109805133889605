import { useEffect, useRef, useState } from "react";
import { NavigateFunction, Outlet, useNavigate, useParams } from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { ZenObservable } from "zen-observable-ts";
import { Skeleton } from 'primereact/skeleton';
import { StoreState } from "src/reducers";
import {
    fetchFamilyRecordings, fetchGeneralRecordings, fetchRecordingSlots, fetchPreCommunicationSlots,
    getUser
} from "src/actions";
import { CameraIcon } from "src/icons";
import { filterDevicesWithPatient } from "src/utils/generalMethods";
import { DeviceModel } from "src/models/device.model";
import { IcuFeatureFlagsModel } from "src/models/icu.model";
import { GenericSubscriptionActions, GenericSubscriptionResources } from "src/constans/genericSubscription.enum";
import IotService from "src/services/iot.service";
import { VideoStreamBox } from "src/components/patientView/VideoStreamBox";
import { PatientSideBar } from "src/components/patientView/PatientSideBar";
import { PetientActionMenu } from "src/components/patientView/PatientActionMenu";

interface PatientViewPageSelectorResult {
    devices: DeviceModel[] | undefined;
    isDevicesObjUpdated: boolean;
    featureFlags: IcuFeatureFlagsModel;
}

const PatientViewPageSelector = ({icus, deviceObject} : StoreState): PatientViewPageSelectorResult => {
    return {
        devices: filterDevicesWithPatient(deviceObject.devices),
        isDevicesObjUpdated: deviceObject?.isDevicesObjUpdated || false,
        featureFlags: icus[0]?.feature_flags
    }
}

export const PatientViewPage = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {devices, isDevicesObjUpdated, featureFlags} = useSelector(PatientViewPageSelector, shallowEqual);
    const navigate: NavigateFunction = useNavigate();
    const params = useParams();
    const [isVideoStreamReady, setIsVideoStreamReady] = useState(false);
    const [currentDevice, setCurrentDevice] = useState<DeviceModel>();
    const videoStreamRef = useRef<any>(null);
    const tenantId = JSON.parse(getUser()).tenantId;

    const openVideoStream = () => {
        videoStreamRef.current.show();
    }

    const hideVideoStream = () => {
        videoStreamRef.current.hide();
    }

    const findCurrentDeviceOrRedirect = (deviceId: string | undefined) => {
        if (!deviceId && devices && devices[0]) {
            navigate(`/patientview/${devices[0].id}`);
        } else {
            return devices?.find(({id}) => id === deviceId);
        }
    };

    const _reconnectDeviceGenericTopic = (tenantId: string, icuId: string, deviceId: string, patientId: string) => {
        setTimeout(() => {
            subscribeToGenericDeviceTopic(tenantId, icuId, deviceId, patientId);
        }, 10000);
    };

    const subscribeToGenericDeviceTopic = (tenantId: string, icuId: string, deviceId: string, patientId: string) => {
        const topic = `${tenantId}/${icuId}/${deviceId}/generic`;

        const subscription =  IotService.subscribeToTopic(topic,
            (data: any) => {
                if(data.value.action === GenericSubscriptionActions.CREATE) {
                    switch (data.value.resource) {
                        case GenericSubscriptionResources.FAMILY_MESSAGE:
                            fetchFamilyRecordings(patientId)
                            return;
                        default:
                            return;
                    }
                }
            },
            (err: any) => {
                console.log(`Error: ${JSON.stringify(err)}`);
            },
            () => {
                _reconnectDeviceGenericTopic(tenantId, icuId, deviceId, patientId);
            }
        );

        return subscription;
    }

    const updateStreamStatus = (isVideoStreamReady: boolean) => {
        setIsVideoStreamReady(isVideoStreamReady);
    }

    const renderSideBarContent = () => {
        if (!isDevicesObjUpdated) return <Skeleton height="100%" className="overview__data skeleton"/>

        if (devices?.length) return <PatientSideBar devices={devices} activeDeviceId={params.deviceId}/>

        return (
            <div className="patient-view-page__empty-message">
                {t("No patient found")}
            </div>
        )
    }

    useEffect(() => {
        const device = findCurrentDeviceOrRedirect(params.deviceId);

        if (device && devices && device?.id !== currentDevice?.id) {
            console.log(device);
            setCurrentDevice(device);

            if(!!device.patient?._id) {
                if (featureFlags?.recordings?.family_recordings_management) {
                    dispatch(fetchFamilyRecordings(device.patient._id));
                }
                if (featureFlags?.recordings?.general_recordings_management) {
                    dispatch(fetchGeneralRecordings(device.patient._id));
                    dispatch(fetchRecordingSlots(device.patient._id));
                    dispatch(fetchPreCommunicationSlots(device.patient._id, "Stimuli"))
                }
            }

            hideVideoStream();
        }
    }, [params.deviceId, devices?.length]);

    useEffect(() => {
        let subscription: ZenObservable.Subscription;
        if(params.deviceId){
            const device = findCurrentDeviceOrRedirect(params.deviceId);
            if (device && device.patient?._id) {
                subscription = subscribeToGenericDeviceTopic(tenantId, device.icu_id, params.deviceId, device.patient._id);
            }
        }
        return () => {
            if(subscription) {
                IotService.unsubscribeToTopic(subscription);
            }
        };
    }, [params.deviceId]);

    return (
        <div className="patient-view-page">
            <div className="patient-view-page__header-box">
            <div className="patient-view-page__title">
                    {t("Patient View")}
                </div>
                {devices?.length ? (
                    <div className="patient-view-page__header-buttons">
                        <button
                            disabled={isVideoStreamReady}
                            onClick={openVideoStream}
                            className={`patient-view-page__camera-button button button-regular button-camera ${
                                isVideoStreamReady ? "button-camera--disabled" : ""
                            }`}
                        >
                            <CameraIcon color="#fff" />
                        </button>
                        <div className="patient-view-page__action-button">
                            <PetientActionMenu featureFlags={featureFlags} deviceId={params.deviceId} />
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="patient-view-page__main">
                <div className="patient-view-page__side-bar">
                    {renderSideBarContent()}
                </div>
                <div className="patient-view-page__information-screens">
                    <Outlet />
                </div>
            </div>
            <VideoStreamBox
                ref={videoStreamRef}
                tenantId={tenantId}
                deviceId={currentDevice?.id}
                updateStreamStatus={updateStreamStatus}
            />
        </div>
    );
};
